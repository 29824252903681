<template>

    <div class='list_content'>
        <h2 style="text-align: center;">添加用户</h2>
        <el-form ref="form" :model="user" label-width="80px">
            <el-form-item label="*用户名" style="margin-left: 0px;">
                <el-tooltip class="item" effect="dark" content="用户登录的账号，一般为客户的单位简称" placement="right">
                    <el-input v-model="user.user_name"></el-input>
                </el-tooltip>
            </el-form-item>
            <el-form-item label="*密码">
                <el-input show-password v-model="user.password"></el-input>
            </el-form-item>
            <el-form-item label="*级别">
                <el-select v-model="user.user_level" placeholder="请选择">
                    <el-option label="普通会员" value=3></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="*用户状态">
                <el-select v-model="user.use_status" placeholder="请选择">
                    <el-option label="启用" value=1></el-option>
                    <el-option label="禁用" value=2></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="*运营状态">
                <el-select v-model="user.yunying_state" placeholder="请选择">
                    <el-option label="渠道" value=1></el-option>
                    <el-option label="自运营" value=2></el-option>
                    <el-option label="合作" value=3></el-option>
                    <el-option label="渠转自" value=4></el-option>
                    <el-option label="自转渠" value=5></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="*来源">
                <el-select v-model="user.app_name" placeholder="请选择">
                    <el-option label="字节-抖音" value="字节-抖音"></el-option>
                    <el-option label="字节-今日头条" value="字节-今日头条"></el-option>
                    <el-option label="微信" value="微信"></el-option>
                    <el-option label="快手" value="快手"></el-option>
                    <el-option label="百度" value="百度"></el-option>
                    <el-option label="小红书" value="小红书"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="*单位名称" width="120px">
                <el-input v-model="user.comp_name">
                </el-input>
            </el-form-item>
            <el-form-item label="*科室类型" width="120px">
                <el-input v-model="user.ks_type">
                </el-input>
            </el-form-item>

            <el-form-item label="*省份">
                <el-input v-model="user.province_name"></el-input>
            </el-form-item>

            <el-form-item label="*城市">
                <el-input v-model="user.city_name"></el-input>
            </el-form-item>

            <el-form-item label="*充值条数">
                <el-input v-model="user.pay_num"></el-input>
            </el-form-item>

            <el-button type="primary" @click="onSubmit">确认</el-button>
            <el-button @click="goback">取消</el-button>
        </el-form>
    </div>

</template>

<script>
import LeftMenu from '@/components/left.vue'
import HeaderTop from '@/components/top.vue'
import Footer from '@/components/footer.vue'

export default {
    name: 'UserAddView',
    // 注册组件
    components: {
        LeftMenu,
        HeaderTop,
        Footer
    },
    props: {},
    // data() 为变量赋值等
    data() {
        return {
            cache_user: {},
            user: {},
        }
    },

    created() {
        // 从缓存获取user
        this.cache_user = JSON.parse(sessionStorage.getItem('user'))
    },

    methods: {
        onSubmit() {
            console.log('开始添加!');
            const that = this
            that.user.man_level = Number(that.cache_user.user_level)
            that.user.user_level = Number(that.user.user_level)
            that.user.use_status = Number(that.user.use_status)
            that.user.yunying_state = Number(that.user.yunying_state)
            that.user.pay_num = Number(that.user.pay_num)
            this.myAxios.post(that.myBaseurl + '/user/add', that.user)
                .then(function (res) {
                    if (res.data.Code === '200') {
                        console.log(res);
                        alert("添加成功 !")
                        that.$router.push({
                            path: '/user/list',
                        })
                    } else {
                        alert(res.data.Msg)
                        return
                    }
                })
        },

        goback() {
            history.go(-1)
        }
    }
}

</script>

<style scoped="scoped">
/*大框体样式*/
.el-main {
    height: calc(100vh - 120px);
}

.list_content {
    max-width: 800px;
}

.el-form-item__label {
    max-width: 200px !important;
}
</style>
